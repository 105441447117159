import SiteButton_CircleButtonComponent from '@wix/thunderbolt-elements/src/components/SiteButton/viewer/skinComps/BaseButton/CircleButton.skin';


const SiteButton_CircleButton = {
  component: SiteButton_CircleButtonComponent
};


export const components = {
  ['SiteButton_CircleButton']: SiteButton_CircleButton
};


// temporary export
export const version = "1.0.0"
